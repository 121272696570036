
import { defineComponent } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ApplyContract",
  components: { GuestLayout },
  setup() {
    const router = useRouter();

    const applyContract = () => {
      router.push({ name: "FullyCompleteRegistration" });
    };

    const moreInformation = () => {
      router.push({ name: "MoreInformation" });
    };

    return {
      applyContract,
      moreInformation,
    };
  },
});
